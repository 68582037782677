<template>
  <div>
    <a-upload-dragger
      v-if="type === 'image'"
      :file-list="fileList"
      :before-upload="beforeUpload"
      :multiple="false"
      :showUploadList=false
      accept=".PNG,.png,image/png,.jpeg,.JPEG,image/jpeg"
      name="File"
      @change="handleChange"
      >
      <p class="ant-upload-drag-icon">
          <a-icon type="inbox"/>
      </p>
      <p class="ant-upload-text">
          Click or drag file to this area to upload
      </p>
      <p class="ant-upload-text-secondary">
          Max. size: 4MB
      </p>
    </a-upload-dragger>
    <div :style="disabled ? 'opacity: 60%;' : ''">
      <a-upload-dragger
        :disabled="disabled ? disabled : false"
        v-if="type === 'video'"
        :file-list="fileList"
        :before-upload="beforeUpload"
        :multiple="false"
        :showUploadList=false
        accept=".mp4,video/mp4,.webm,video/webm,.3gp,video/3gp"
        name="File"
        @change="handleChange"
        >
        <p class="ant-upload-drag-icon">
            <a-icon type="inbox"/>
        </p>
        <p class="ant-upload-text">
            Click or drag file to this area to upload
        </p>
        <p class="ant-upload-text-secondary">
            Max. size: 32MB
        </p>
      </a-upload-dragger>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { createCreative } from '@/api/display/creative'

export default {
  props: ['type', 'fileList', 'receivedCreatives', 'disabled'],
  data() {
    return {
      fileListValue: this.fileList,
    }
  },
  methods: {
    beforeUpload(file) {
      if (Math.round(file.size / 1024) <= (this.type === 'image' ? 4096 : 32768)) {
        this.fileListValue = [...this.fileListValue, file]
        this.$emit('before-upload', { fileList: this.fileListValue })
        return false
      } else {
        Vue.prototype.$notification.error({
          message: 'File is too big',
          description: `Maximum ${this.type === 'image' ? '4' : '32'} MB allowed.`,
        })
        return false
      }
    },
    handleChange(e) {
      if (this.type === 'image' && (e.file.type === 'image/png' || e.file.type === 'image/jpeg')) {
        if (e.fileList.length === 1 && e.file.size / 1024 <= 4096) {
          const selectedFile = e.file
          createCreative(selectedFile, selectedFile.type)
            .then(response => {
              if (response.Creative !== undefined) {
                this.receivedCreatives.push(response.Creative)
              }
            })
        } else if (e.fileList.length > 1) {
          const selectedFile = e.fileList[e.fileList.length - 1].originFileObj
          createCreative(selectedFile, selectedFile.type)
            .then(response => {
              if (response.Creative !== undefined) {
                this.receivedCreatives.push(response.Creative)
              }
            })
        }
      } else if (this.type === 'video' && (e.file.type === 'video/mp4' || e.file.type === 'video/webm' || e.file.type === 'video/3gp')) {
        if (e.fileList.length === 1 && e.file.size / 1024 <= 32768) {
          const selectedFile = e.file
          createCreative(selectedFile, selectedFile.type)
            .then(response => {
              if (response.Creative !== undefined) {
                this.receivedCreatives.push(response.Creative)
              }
            })
        } else if (e.fileList.length > 1) {
          const selectedFile = e.fileList[e.fileList.length - 1].originFileObj
          createCreative(selectedFile, selectedFile.type)
            .then(response => {
              if (response.Creative !== undefined) {
                this.receivedCreatives.push(response.Creative)
              }
            })
        }
      }
    },
  },
}
</script>

<style>
.ant-upload-text-secondary {
  font-size: 90%;
}
.ant-upload-drag-icon {
  margin-bottom: 15px !important;
}
</style>
